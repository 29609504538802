import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import Accordion from '../../../components/Accordion/Accordion'
import Heading, {
    HeadingVariant,
    Level,
} from '../../../components/Typography/Heading'
import Button, { Action } from '../../../components/Button/Button'
import Radio from '../../../components/Form/Radio'
import Input, { InputType } from '../../../components/Form/Input'
import Label from '../../../components/Form/Label'
import { UserContext } from '../../../providers/UserProvider'
import {
    MollieMethod,
    PaymentMethodUpdate,
} from '../../../plugins/middleware-api-client'
import { useTranslation } from 'react-i18next'
import { FormOption } from '../../../components/Form/Select'
import { Link } from 'react-router-dom'
import { PaymentMethodContext } from '../../../providers/PaymentMethodProvider'
import FileInput from '../../../components/Form/FileInput'

interface PaymentMethodProps {
    paymentMethod: MollieMethod
    selectedCountry: FormOption
    isArrangingMethods: boolean
}

const PaymentMethodComponent: React.FC<PaymentMethodProps> = ({
    paymentMethod,
    selectedCountry,
    isArrangingMethods,
}) => {
    const { t } = useTranslation()
    const userContext = useContext(UserContext)
    const paymentMethodContext = useContext(PaymentMethodContext)
    const [statusColor, setStatusColor] = useState<string>(
        'bg-brand-primary border-brand-primary',
    )
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [enabled, setEnabled] = useState<boolean>(paymentMethod.enabled)
    const [paymentMethodUpdate, setPaymentMethodUpdate] =
        useState<PaymentMethodUpdate>({
            country: null,
            mollie_name: paymentMethod.id,
            name: paymentMethod.name ?? null,
            logo: paymentMethod.image,
            minValue:
                paymentMethod.custom_minimum_amount?.value ??
                paymentMethod.minimum_amount.value ??
                0.01,
            maxValue:
                paymentMethod.custom_maximum_amount?.value ??
                paymentMethod.maximum_amount.value ??
                null,
            enabled: paymentMethod.enabled,
            surcharge_enabled: paymentMethod.surcharge_enabled,
            surcharge_fixed_amount: paymentMethod.surcharge_fixed_amount,
            surcharge_percent_amount: 0, // paymentMethod.surcharge_percent_amount,
            surcharge_vat_amount: paymentMethod.surcharge_vat_amount,
        })

    const savePaymentMethod = () => {
        setIsSaving(true)
        userContext
            .updatePaymentMethod(paymentMethodUpdate, selectedCountry.value)
            .then(() => {
                setIsSaving(false)
                paymentMethodContext.setOpenId(paymentMethod.id)
            })
    }

    useEffect(() => {
        setPaymentMethodUpdate((f) => ({
            ...f,
            country: selectedCountry.value,
        }))
    }, [selectedCountry])

    useEffect(() => {
        if (enabled !== paymentMethodUpdate.enabled) {
            let paymentMethodUpdatePayload = paymentMethodUpdate
            paymentMethodUpdatePayload.enabled = enabled
            userContext
                .updatePaymentMethod(
                    paymentMethodUpdatePayload,
                    selectedCountry.value,
                )
                .then(() => {
                    setPaymentMethodUpdate((f) => ({
                        ...f,
                        enabled: enabled,
                    }))
                })
        }
    }, [enabled])

    useEffect(() => {
        if (
            isArrangingMethods &&
            paymentMethodContext.isOpenId(paymentMethod.id)
        ) {
            cancelEdit()
        }
    }, [isArrangingMethods])

    useEffect(() => {
        if (paymentMethod.enabled !== enabled) {
            setEnabled(paymentMethod.enabled)
        }
        setPaymentMethodUpdate((f) => ({
            ...f,
            mollie_name: paymentMethod.id,
            name: paymentMethod.name ?? null,
            logo: paymentMethod.image,
            minValue:
                paymentMethod.custom_minimum_amount?.value ??
                paymentMethod.minimum_amount.value ??
                0.01,
            maxValue:
                paymentMethod.custom_maximum_amount?.value ??
                paymentMethod.maximum_amount.value ??
                null,
            enabled: paymentMethod.enabled,
            mollie_components_enabled: paymentMethod.mollie_components_enabled,
            mollie_save_creditcard: paymentMethod.mollie_save_creditcard,
            ideal_issuers_enabled: paymentMethod.ideal_issuers_enabled,
        }))
    }, [paymentMethod])

    const cancelEdit = () => {
        paymentMethodContext.setOpenId('')
    }

    useEffect(() => {
        switch (paymentMethod.status) {
            case 'activated':
                setStatusColor('bg-brand-primary border-brand-primary')
                break
            case 'pending-boarding':
            case 'pending-review':
            case 'pending-external':
                setStatusColor('bg-brand-yellow border-brand-yellow')
                break
            default:
                break
        }
    }, [paymentMethod])

    const updateMaxValue = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.valueAsNumber
        if (
            paymentMethod.maximum_amount.value &&
            e.target.valueAsNumber > paymentMethod.maximum_amount.value
        ) {
            value = paymentMethod.maximum_amount.value
        }
        setPaymentMethodUpdate((f) => ({
            ...f,
            maxValue: value,
        }))
    }

    const updateMinValue = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.valueAsNumber
        if (
            paymentMethod.minimum_amount.value &&
            e.target.valueAsNumber < paymentMethod.minimum_amount.value
        ) {
            value = paymentMethod.minimum_amount.value
        }
        setPaymentMethodUpdate((f) => ({
            ...f,
            minValue: value,
        }))
    }

    const updateImage = async (file: File | null) => {
        if (!file) return
        let base64Image = await getBase64(file)
        if (typeof base64Image === 'string') {
            setPaymentMethodUpdate((f) => ({
                ...f,
                logo: String(base64Image),
            }))
        }
    }

    const updateMollieComponentsToggle = (enabled: boolean) => {
        setPaymentMethodUpdate((f) => ({
            ...f,
            mollie_components_enabled: enabled,
        }))
    }

    const updateSaveCreditcardToggle = (enabled: boolean) => {
        setPaymentMethodUpdate((f) => ({
            ...f,
            mollie_save_creditcard: enabled,
        }))
    }

    const updateIdealIssuersToggle = (enabled: boolean) => {
        setPaymentMethodUpdate((f) => ({
            ...f,
            ideal_issuers_enabled: enabled,
        }))
    }

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(file)
        })
    }

    return (
        <Accordion
            classList="py-2 flex-grow"
            isOpen={paymentMethodContext.isOpenId(paymentMethod.id)}
        >
            <Accordion.Header
                isOpen={paymentMethodContext.isOpenId(paymentMethod.id)}
            >
                <div className="flex justify-between">
                    <div className="flex items-center">
                        <div className="rounded h-8 w-8 flex shrink">
                            <img
                                className="m-auto"
                                src={paymentMethod.image}
                                alt={paymentMethod.name}
                            />
                        </div>
                        <div className="pl-3">
                            <Heading
                                headingVariant={HeadingVariant.BLACK}
                                level={Level.HEADING_6}
                            >
                                {paymentMethod.name}
                            </Heading>
                        </div>
                    </div>
                    {!isArrangingMethods && paymentMethod.status !== null && (
                        <div className="flex items-center">
                            {!paymentMethodContext.isOpenId(
                                paymentMethod.id,
                            ) && (
                                <Button
                                    action={Action.GRAY}
                                    inverted={true}
                                    onClick={() =>
                                        paymentMethodContext.setOpenId(
                                            paymentMethod.id,
                                        )
                                    }
                                >
                                    {t('payment:settings.manage')}
                                </Button>
                            )}
                            <Radio
                                enabled={enabled}
                                setEnabled={(enabled) => setEnabled(enabled)}
                                statusColor={statusColor}
                            />
                        </div>
                    )}
                    {paymentMethod.status === null && (
                        <div className="flex items-center underline text-xs">
                            <Link to="/">{t('common:disabled')}</Link>
                        </div>
                    )}
                </div>
            </Accordion.Header>
            {!isArrangingMethods && (
                <Accordion.Body
                    isOpen={
                        paymentMethodContext.isOpenId(paymentMethod.id) &&
                        !isArrangingMethods
                    }
                >
                    <div className="flex max-w-2xl py-4 lg:justify-between lg:items-center flex-col lg:flex-row">
                        <Input
                            label={t('payment:settings.name') || ''}
                            defaultValue={
                                !paymentMethod.default_name
                                    ? paymentMethodUpdate.name ?? ''
                                    : ''
                            }
                            onChange={(e) => {
                                setPaymentMethodUpdate((f) => ({
                                    ...f,
                                    name: e.target.value,
                                }))
                            }}
                            placeholder={
                                t('payment:settings.name-placeholder') || ''
                            }
                        />
                    </div>
                    <div className="flex max-w-2xl py-4 lg:justify-between lg:items-center flex-col lg:flex-row">
                        <FileInput
                            label={t('payment:settings.logo') || ''}
                            isOpen={paymentMethodContext.isOpenId(paymentMethod.id)}
                            isSaving={isSaving}
                            onAdd={(file: File | null) => {
                                updateImage(file)
                            }}
                            onRemove={() => {
                                setPaymentMethodUpdate((f) => ({
                                    ...f,
                                    logo: paymentMethod.image,
                                }))
                            }}
                        />
                    </div>
                    <div className="flex max-w-2xl py-4 lg:items-center flex-col lg:flex-row">
                        <Label
                            classList="m-0 w-full lg:w-1/2 font-medium text-sm text-gray-600"
                            id="min-max"
                        >
                            {t('payment:settings.value') || ''}
                        </Label>
                        <div className="w-full lg:w-1/2 flex">
                            <input
                                id="min"
                                type="number"
                                min={paymentMethod.minimum_amount.value ?? 0.01}
                                max={paymentMethod.maximum_amount.value ?? ''}
                                step="1"
                                className={`text-center focus:border-black focus:border-opacity-10 focus:ring-black focus:ring-opacity-20 min-w-20 mr-6 border-black border-opacity-10 block py-2 px-3 w-full rounded-3px border text-sm leading-4 appearance-none focus:outline-none focus:border-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40`}
                                placeholder={
                                    t(
                                        'payment:settings.value-min-placeholder',
                                    ) || ''
                                }
                                value={paymentMethodUpdate.minValue ?? 0.01}
                                onChange={updateMinValue}
                            />
                            <input
                                id="max"
                                type="number"
                                step="1"
                                min={paymentMethod.minimum_amount.value ?? 0.01}
                                max={paymentMethod.maximum_amount.value ?? ''}
                                className={`text-center focus:border-black focus:border-opacity-10 focus:ring-black focus:ring-opacity-20 min-w-20 border-black border-opacity-10 block py-2 px-3 w-full rounded-3px border text-sm leading-4 appearance-none focus:outline-none focus:border-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40`}
                                placeholder={
                                    t(
                                        'payment:settings.value-max-placeholder',
                                    ) || ''
                                }
                                value={paymentMethodUpdate.maxValue ?? ''}
                                onChange={updateMaxValue}
                            />
                        </div>
                    </div>
                    <div className="flex py-4">
                        <Label
                            classList="m-0 w-full lg:w-1/2 font-medium text-sm text-gray-600"
                            id="surcharge"
                        >
                            {t('payment:settings.surcharge')}
                        </Label>
                        <Radio
                            enabled={paymentMethodUpdate.surcharge_enabled}
                            setEnabled={(enabled) =>
                                setPaymentMethodUpdate((f) => ({
                                    ...f,
                                    surcharge_enabled: enabled,
                                }))
                            }
                            statusColor={
                                paymentMethodUpdate.surcharge_enabled
                                    ? 'bg-brand-primary border-brand-primary'
                                    : 'bg-brand-yellow border-brand-yellow'
                            }
                        />
                    </div>
                    <Accordion.Body
                        isOpen={paymentMethodUpdate.surcharge_enabled}
                        classList="flex max-w-2xl py-4 lg:items-center flex-col lg:flex-row"
                    >
                        <div className="m-0 w-0 lg:w-1/2 font-medium text-sm text-gray-600"></div>
                        <div className="w-full lg:w-1/2">
                            <div className="">
                                {' '}
                                {/* Add flex back here when adding variable rate back */}
                                <div className="mr-2">
                                    <Label
                                        classList="my-2 w-full font-medium text-sm text-gray-600"
                                        id="surcharge-fixed"
                                    >
                                        {t(
                                            'payment:settings.surcharge-fixed',
                                        ) || ''}
                                    </Label>
                                    <input
                                        id="surcharge_fixed"
                                        type="number"
                                        step="0.01"
                                        className={`text-center focus:border-black focus:border-opacity-10 focus:ring-black focus:ring-opacity-20 min-w-20 mr-6 border-black border-opacity-10 block py-2 px-3 w-full rounded-3px border text-sm leading-4 appearance-none focus:outline-none focus:border-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40`}
                                        placeholder={
                                            t(
                                                'payment:settings.surcharge-fixed-placeholder',
                                            ) || ''
                                        }
                                        value={
                                            (paymentMethodUpdate.surcharge_fixed_amount ??
                                                0) / 100
                                        }
                                        onChange={(e) => {
                                            setPaymentMethodUpdate((f) => ({
                                                ...f,
                                                surcharge_fixed_amount: (Number(
                                                    e.target.value,
                                                ) * 100) as number | null,
                                            }))
                                        }}
                                    />
                                </div>
                                {/* <div className="ml-2">
                                <Label
                                    classList="my-2 w-full font-medium text-sm text-gray-600"
                                    id="surcharge-percentage"
                                >
                                    {t('payment:settings.surcharge-percentage')}
                                </Label>
                                <input
                                    id="surcharge_percentage"
                                    type="number"
                                    step="0.01"
                                    className={`text-center focus:border-black focus:border-opacity-10 focus:ring-black focus:ring-opacity-20 min-w-20 border-black border-opacity-10 block py-2 px-3 w-full rounded-3px border text-sm leading-4 appearance-none focus:outline-none focus:border-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40`}
                                    placeholder={t(
                                        'payment:settings.surcharge-percentage-placeholder',
                                    )}
                                    value={
                                        (paymentMethodUpdate.surcharge_percent_amount ??
                                            0) / 100
                                    }
                                    onChange={(e) => {
                                        setPaymentMethodUpdate((f) => ({
                                            ...f,
                                            surcharge_percent_amount: (Number(
                                                e.target.value,
                                            ) * 100) as number | null,
                                        }))
                                    }}
                                />
                            </div> */}
                            </div>
                            <div className="mt-2">
                                <Label
                                    classList="m-0 w-full font-medium text-sm text-gray-600"
                                    id="surcharge-type"
                                >
                                    {t('payment:settings.surcharge-vat') || ''}
                                </Label>
                            </div>
                            <div className="flex">
                                <input
                                    id="surcharge_vat"
                                    type="number"
                                    step="0.01"
                                    className={`text-center focus:border-black focus:border-opacity-10 focus:ring-black focus:ring-opacity-20 min-w-20 border-black border-opacity-10 block py-2 px-3 w-full rounded-3px border text-sm leading-4 appearance-none focus:outline-none focus:border-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40`}
                                    placeholder={
                                        t(
                                            'payment:settings.surcharge-vat-placeholder',
                                        ) || ''
                                    }
                                    value={
                                        paymentMethodUpdate.surcharge_vat_amount ??
                                        0
                                    }
                                    onChange={(e) => {
                                        setPaymentMethodUpdate((f) => ({
                                            ...f,
                                            surcharge_vat_amount: Number(
                                                e.target.value,
                                            ) as number | null,
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </Accordion.Body>
                    {paymentMethod.id === 'creditcard' && (
                        <div>
                            <div className="flex py-4">
                                <Label
                                    classList="m-0 w-full lg:w-1/2 font-medium text-sm text-gray-600"
                                    id="mollie-components-toggle"
                                >
                                    {t(
                                        'payment:settings.value-mollie-components',
                                    )}
                                </Label>
                                <Radio
                                    enabled={
                                        paymentMethodUpdate.mollie_components_enabled ||
                                        false
                                    }
                                    setEnabled={(enabled) =>
                                        updateMollieComponentsToggle(enabled)
                                    }
                                    statusColor={
                                        paymentMethodUpdate.mollie_components_enabled
                                            ? 'bg-brand-primary border-brand-primary'
                                            : 'bg-brand-yellow border-brand-yellow'
                                    }
                                />
                            </div>
                            <div className="flex py-4">
                                <Label
                                    classList="m-0 w-full lg:w-1/2 font-medium text-sm text-gray-600"
                                    id="mollie-creditcard-toggle"
                                >
                                    {t(
                                        'payment:settings.value-mollie-save-creditcard',
                                    )}
                                </Label>
                                <Radio
                                    enabled={
                                        paymentMethodUpdate.mollie_save_creditcard ||
                                        false
                                    }
                                    setEnabled={(enabled) =>
                                        updateSaveCreditcardToggle(enabled)
                                    }
                                    statusColor={
                                        paymentMethodUpdate.mollie_save_creditcard
                                            ? 'bg-brand-primary border-brand-primary'
                                            : 'bg-brand-yellow border-brand-yellow'
                                    }
                                />
                            </div>
                        </div>
                    )}
                    <div className="flex justify-center items-center pb-8 pt-10">
                        <Button
                            action={Action.GRAY}
                            inverted={true}
                            onClick={() => cancelEdit()}
                        >
                            {t('common:actions.cancel')}
                        </Button>
                        <Button
                            loading={isSaving}
                            action={Action.BLUE}
                            onClick={() => savePaymentMethod()}
                        >
                            {t('common:actions.save')}
                        </Button>
                    </div>
                </Accordion.Body>
            )}
        </Accordion>
    )
}

export default PaymentMethodComponent
