import React, {
    ChangeEvent,
    DragEvent,
    useEffect,
    useRef,
    useState,
} from 'react'
import Label from './Label'
import { useTranslation } from 'react-i18next'

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    isSaving: boolean
    isOpen: boolean
    onAdd: (file: File | null) => void
    onRemove: () => void
}

const FileInput: React.FC<FileInputProps> = ({
    label,
    id,
    isSaving,
    isOpen,
    onAdd,
    onRemove,
    ...rest
}) => {
    const [isOver, setIsOver] = useState(false)
    const [file, setFile] = useState<File | null>(null)

    const inputRef = useRef<HTMLInputElement | null>(null)

    const { t } = useTranslation()

    useEffect(() => {
        if (!isSaving) setFile(null)
    }, [isSaving])

    useEffect(() => {
        if (!isOpen) setFile(null)
    }, [isOpen]);

    useEffect(() => {
        if (!file) {
            onRemove()
        } else {
            onAdd(file)
        }
    }, [file])

    const onInput = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()

        if (!e.target.files || e.target.files.length === 0) return
        setFile(e.target.files[0])
    }

    const removeFile = () => {
        setFile(null)
    }

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsOver(true)
    }

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsOver(false)
    }

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsOver(false)

        if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return

        setFile(e.dataTransfer.files[0])
    }

    return (
        <>
            {label && (
                <Label
                    classList="m-0 w-full lg:w-1/2 font-medium text-sm text-gray-600"
                    id={id}
                >
                    {label}
                </Label>
            )}
            <div className="w-full lg:w-1/2">
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div
                        className={`max-w-lg flex justify-center bg-brand-primary ${
                            isOver ? 'bg-opacity-40' : 'bg-opacity-10'
                        } px-6 py-5 border-2 border-brand-primary border-dashed rounded-md`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <div className="space-y-1 text-center">
                            <div className="flex text-xs text-brand-gray-600 leading-3">
                                <p className="pr-1">
                                    {t('payment:settings.drag-drop')}
                                </p>
                                <label
                                    htmlFor={id}
                                    className="relative cursor-pointer rounded-md font-medium text-brand-primary underline hover:text-brand-primary-600"
                                >
                                    <span>{t('payment:settings.browse')}</span>
                                    <input
                                        {...rest}
                                        id={id}
                                        name={id}
                                        ref={inputRef}
                                        type="file"
                                        className="sr-only"
                                        onInput={onInput}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    {file && (
                        <div className="flex flex-row py-2 text-xs text-gray-600">
                            <button
                                onClick={removeFile}
                                className="flex items-center font-bold text-brand-gray-300"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 text-brand-red mr-3`}
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11,9 L9,9 L5,9 L5,11 L9,11 L11,11 L15,11 L15,9 L11,9 Z M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z"
                                    />
                                </svg>
                            </button>
                            {file.name}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default FileInput
